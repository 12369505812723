import React, { useCallback, useEffect, useState } from 'react';
import SliderItem from './SliderItem/SliderItem';
import SliderCircleButtons from './SliderCircleButtons/SliderCircleButtons';
import { SliderInterface } from '../../../Core/Interfaces/SliderInterface';
import { AppData } from '../../../Core/AppData';
import { CmsDataInterface } from '../../../Core/Interfaces/CmsDataInterface';

const Slider = () => {
    const appDataWithSlide: CmsDataInterface[] = AppData.filter((item: CmsDataInterface) => item.hasSlider);
    const filterSlideDataFromAppData = (): SliderInterface[] => {
        let tempSlideList: SliderInterface[] = [];
        appDataWithSlide.forEach(item => {
            if (item.sliderData) {
                tempSlideList.push(item.sliderData);
            }
        })
        return tempSlideList;
    }
    const slideList: SliderInterface[] = filterSlideDataFromAppData();
    const [currentIndex, setCurrentIndex] = useState(0);
    const goToNext = useCallback(() => {
        const isLastSlide = currentIndex === slideList.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }, [currentIndex, slideList]);
    const goToSelected = (index: number): void => {
        setCurrentIndex(index);
    }
    useEffect(() => {
        const slideTimeOut = setTimeout(() => {
            goToNext();
        }, 5000);
        return () => {
            clearTimeout(slideTimeOut);
        };
    }, [goToNext, currentIndex]);
    return (
        <>
            <div
                className="page-start-slider">
                {slideList.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={currentIndex === index ? "page-start-subject active-subject" : "page-start-subject"}>
                            <SliderItem
                                key={index}
                                sectionKey={appDataWithSlide[index].sectionKey}
                                slide={item}></SliderItem>
                        </div>
                    )
                })}
            </div>
            <SliderCircleButtons
                slideListLength={slideList.length}
                currentSlideIndex={currentIndex}
                goToSelected={goToSelected}
            />
        </>
    );
}

export default Slider;
