import React from 'react';
import { Trans } from 'react-i18next';

import { TitleDetailGroupInterface } from '../../Core/Interfaces/TitleDetailGroupInterface';

const TitlePrimaryCreator: React.FC<{ data: TitleDetailGroupInterface, sectionKey: string }> = ({
                                                                                                    data,
                                                                                                    sectionKey
                                                                                                }) => {
    return (
        <>
            <div
                className="page-content-title">
                <Trans
                    i18nKey={sectionKey + "." + data.title}></Trans>
            </div>
            <div
                className="page-content-detail">
                <Trans
                    i18nKey={sectionKey + "." + data.detail}></Trans>
            </div>
        </>
    );
}

export default TitlePrimaryCreator;
