import React from 'react';
import { ListInterface } from '../../Core/Interfaces/ListInterface';
import { Trans } from 'react-i18next';

const CardCreator: React.FC<{ data: ListInterface[], sectionKey: string }> = ({
                                                                                  data,
                                                                                  sectionKey
                                                                              }) => {
    return (
        <div
            className="box-hosting-wrapper">
            {data.map((item, index) => (
                <div
                    className="box-hosting-pack"
                    key={index}>
                    <div
                        className="box-hosting-pack-title">
                        <strong>
                            <Trans
                                i18nKey={sectionKey + "." + item.title}>
                            </Trans>
                        </strong>
                    </div>
                    {item.items.map((subItem, subIndex) => (
                        <div
                            key={subIndex}>
                            <Trans
                                i18nKey={sectionKey + "." + subItem}>
                            </Trans>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default CardCreator;
