import React from 'react';
import { ListInterface } from '../../Core/Interfaces/ListInterface';
import { Trans } from 'react-i18next';

const ListCreator: React.FC<{ data: ListInterface, sectionKey: string }> = ({
                                                                                data,
                                                                                sectionKey
                                                                            }) => {
    return (
        <>
            <div
                className="page-content-part">
                <div
                    className="page-content-sub-title">
                    <Trans
                        i18nKey={sectionKey + "." + data.title}></Trans>
                </div>
                <ul>
                    {data.items.map((item, index) => {
                        return (

                            <li key={index}>
                                <Trans
                                    i18nKey={sectionKey + "." + item}></Trans>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    );
}

export default ListCreator;
