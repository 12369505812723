import React from 'react';
import { Trans } from 'react-i18next';

const CompanyInfo = () => {
    return (
        <>
            <div className="mb-2">
                <h2>Kirilmaz Ltd</h2>
                <p><span className="bold">Company number:</span> 11438464</p>
                16 The Maltings Roydon Road, Stanstead Abbotts, Hertfordshire, England, SG12 8UU
                <p>United Kingdom</p>
                <p>+44 (20) 3677 5575</p>
            </div>
          <div className="mb-2">
              <h2>Kirilmaz Software S.R.L.</h2>
              <p><span className="bold">CIF:</span> 45880720 <span className="bold">EUID:</span> ROONRC.J29/850/2022</p>
              <p>Bucuresti, Sector 3, Str. Ramnicu Valcea, Nr.26, Bl.15b, Sc.1, Et.2, Ap.11, 031811</p>
              <p>Romania</p>
              <p>+40 (31) 630 01 70</p>
          </div>
            <div className="mb-2">
                <h2>Kirilmaz Bilişim San. ve Tic. Ltd. Şti.</h2>
                <p><span className="bold">Mersis No:</span> 0557060700200013</p>
                Zuhuratbaba Mahallesi Zuhuratbaba Caddesi Günaydın Apartmanı 39/10 34147 Bakırköy, İstanbul
                <p>Türkiye</p>
                <p>+90 (212) 570 77 99</p>
            </div>
            <div>
                <h4><Trans
                    i18nKey={"footer.other_numbers"}></Trans></h4>
                <p>+1 (213) 205 01 40 - Los Angeles, CA, United States</p>
                <p>+61 (2) 7251 5633 - Sydney, Australia</p>
            </div>
        </>
    );
}

export default CompanyInfo;
