import React from 'react';
import { CmsDataInterface } from '../../../Core/Interfaces/CmsDataInterface';
import { scroll } from '../../../Core/Helpers/ScrollToView';
import { Trans } from 'react-i18next';

const ToggleNavBarItem: React.FC<{ closeToggleBar: any, section: CmsDataInterface }> = ({ closeToggleBar, section }) => {

    return (
        <div
            className="toggle-navbar-item">
            <a href="#"
               onClick={() => {
                   scroll(section.sectionKey);
                   closeToggleBar();
               }}
               className="menu-top-links">
                <Trans
                    i18nKey={section.sectionKey + ".title"}></Trans>
            </a>
        </div>
    );
}

export default ToggleNavBarItem;
