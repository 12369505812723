import React from 'react';
import { LogoInterface } from '../../Core/Interfaces/LogoInterface';

const LogoGroupCreator: React.FC<{ data: LogoInterface[] }> = ({ data }) => {
    return (
        <>
            {data.map((item, index) => (
                <img
                    key={index}
                    src={item.url}
                    alt={item.alt}
                    className={item.className}
                />
            ))}
        </>
    );
}

export default LogoGroupCreator;
