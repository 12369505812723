export const EnglishKeys = {
    translations: {
        server: {
            title: "Server",
            slide_title_primary: "Server Renting",
            slide_title_secondary: "Web Hosting",
            slide_detail: "We are at your service with our flexible plans for your needs",
            first_segment_title: "Server Rental Service",
            first_segment_detail: "Do you need one or more permanent online servers? We offer high quality and affordable virtual or physical server rental services without operating system and capacity limits.",
            second_segment_title: "Which operating system is right for you?",
            second_segment_detail: "Depending on the need and licensing conditions, we offer your chosen operating system with prices starting from $ 20.",
            list_title: "Other Prices",
            list_item_1: "Additional 1 IP 2.5$ + VAT / Month ( Virtual )",
            list_item_2: "Additional 5 IPs 2.5$ + VAT / Month (Virtual, Physical)",
            list_item_3: "Plesk panel 12$ + VAT / Month ( Virtual )",
            list_item_4: "Plesk panel 60$ + VAT / Month ( Physical )"
        },
        hosting: {
            title: "Hosting",
            first_segment_title: "Hosting Service",
            first_segment_detail: "We are always with you with the most affordable and professional method of publishing your website. With flexible options for your needs, we are in a position to fully respond to your needs with low or high coverage.",
            card_1_title: "Package Amateur",
            card_1_item_1: "1 Email",
            card_1_item_2: "10MB Web Space",
            card_1_item_3: "10MB Email Space",
            card_1_item_4: "PHP + ASP MYSQL / MsSQL",
            card_1_item_5: "4GB Traffic",
            card_1_item_6: "24TL / Year ( KDV Dahil )",
            card_2_title: "Package Wise",
            card_2_item_1: "3 Email Addresses",
            card_2_item_2: "250MB Web Space",
            card_2_item_3: "250MB Email Space",
            card_2_item_4: "PHP / ASP",
            card_2_item_5: "MySQL / MsSQL",
            card_2_item_6: "4GB Traffic",
            card_2_item_7: "72TL / Year ( KDV Dahil )",
            card_3_title: "Package Professional",
            card_3_item_1: "10 Email Address",
            card_3_item_2: "10 GB Quota",
            card_3_item_3: "PHP / ASP",
            card_3_item_4: "MySQL / MsSQL",
            card_3_item_5: "4GB Traffic",
            card_3_item_6: "130TL / Year ( KDV Dahil )",
            list_title: "Other Hosting Prices",
            list_item_1: "1GB over quota 0.2cent + VAT / Month",
            list_item_2: "1 standard e-mail account 12TL + VAT / Year",
            list_item_3: "10 standard e-mail accounts 80TL + VAT / Year",
            list_item_4: "20 standard e-mail accounts 130TL + VAT / Year"
        },
        email: {
            title: "Email",
            slide_title_primary: "Corporate Mail",
            slide_title_secondary: "Bulk Mail",
            slide_detail: "We offer high quality and continuous service",
            first_segment_title: "Corporate Email Service",
            first_segment_detail: "Communication with e-mail, which is the most important of today's communication methods, is a sensitive issue for all companies. With 365 days of uninterrupted service and 24/7 uninterrupted support, we take this burden off your back. Our system, which can be offered for your needs apart from the existing packages, can offer professional or simple use.",
            second_segment_title: "",
            second_segment_detail: "The content of our Corporate Mail service is gathered under two groups. With the additional services and standard services that you can choose for your needs, the group contents are as follows.",
            list_1_title: "Our Standard Service",
            list_1_item_1: "PC / Mobil interface",
            list_1_item_2: "15GB free quota",
            list_1_item_3: "Dynamic SPAM filter ( Black list )",
            list_1_item_4: "Content filtering",
            list_1_item_5: "Antivirus scanning",
            list_1_item_6: "Full redundant streaming",
            list_1_item_7: "DKIM, SPF, DMARC",
            list_1_item_8: "Google Drive, Dropbox, OneDrive connection possibility",
            list_1_item_9: "Online correspondence within the company",
            list_2_title: "Additional Services",
            list_2_item_1: "Cyren Premium Antispam",
            list_2_item_2: "Cyren Zero-hour Antivirus",
            list_2_item_3: "Exchange ActiveSync ( EAS )",
            list_2_item_4: "Exchange Web Services ( EWS )"
        },
        bulk_email: {
            first_segment_title: "Bulk Mail Delivery Service",
            first_segment_detail: "We take the burden of sending regular e-mails off your shoulders, the easiest and most cost-effective way to keep your communication with your customers alive. We recommend that you use mass mail continuously to increase your sales, speed up your customer returns and keep your customer data alive. Our monthly capacity is 7.500.000 e-mails with free e-mail format creation with e-mail content complying with all legal requirements, sending the created content according to customer categories and 10.000 free shipping every month. Important notice: Our company does not provide an e-mail list.",
            second_segment_title: "Bulk Mail Package Prices",
            second_segment_detail: "Our prices for shipments over 10,000 pieces are as given in the table below.",
            list_1_title: "Bulk Mail Service Features",
            list_1_item_1: "Free 10,000 monthly shipping rights",
            list_1_item_2: "Cleaning up incorrect and invalid e-mail addresses",
            list_1_item_3: "Category based submission",
            list_1_item_4: "City-based shipping",
            list_1_item_5: "Tracking of shipments on cards",
            list_1_item_6: "15GB free space usage"
        },
        services: {
            title: "Services",
            slide_title_primary: "Customer Management",
            slide_title_secondary: "ERP & CRM",
            slide_detail: "We take control of all your data",
            first_segment_title: "About ERP & CRM",
            first_segment_detail: "With our ERP and CRM software created by integrating web-based software developed within our company, taking the workload of small / medium / large enterprises off their shoulders; With our 24/7 data processing support portal and our team, we help you advance your business on solid foundations. We carry our program, which is used in the Software, Tourism, Health, Sports, Machinery sectors, to more advanced levels day by day."
        },
        support: {
            title: "Support"
        },
        contact: {
            title: "Contact",
            slide_title_primary: "Would you like to",
            slide_title_secondary: "join our mailing list?",
            slide_detail: ""
        },
        footer: {
            other_numbers: "Other Numbers"
        },
        login: {
            title: "Login",
        }
    }
};
