import React from 'react';

const SliderCircleButtons: React.FC<{ goToSelected: any, slideListLength: number, currentSlideIndex: number }> = ({
                                                                                                                      goToSelected,
                                                                                                                      slideListLength,
                                                                                                                      currentSlideIndex
                                                                                                                  }) => {
    return (
        <>
            <div
                className="page-start-navigate">
                <div
                    className="page-start-navigate-content">
                    {Array.from({ length: slideListLength }).map((item, index) => (
                            <div
                                key={index}
                                id="01"
                                className={currentSlideIndex === index ? "circle active-button" : "circle"}
                                onClick={() => goToSelected(index)}
                            ></div>
                        )
                    )}
                </div>
            </div>
        </>
    );
}

export default SliderCircleButtons;
