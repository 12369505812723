import React from 'react';
import { Trans } from 'react-i18next';
import { SliderInterface } from '../../../../Core/Interfaces/SliderInterface';

const SliderItem: React.FC<{ slide: SliderInterface, sectionKey: string }> = ({
                                                                                  slide,
                                                                                  sectionKey
                                                                              }) => {
    return (
        <>
            <div
                className="page-start-subject-bg">
                <img
                    src={slide.imageUrl}
                    alt=""/>
            </div>
            <div
                className="page-start-content">
                <div
                    className="page-start-title-wrapper">
                    <div
                        className="page-start-subject-title">
                        <Trans
                            i18nKey={sectionKey+".slide_title_primary"}></Trans>
                    </div>
                    <div
                        className="page-start-subject-title">
                        <Trans
                            i18nKey={sectionKey+".slide_title_secondary"}></Trans>
                    </div>
                    <div
                        className="page-start-subject-detail">
                        <Trans
                            i18nKey={sectionKey+".slide_detail"}></Trans>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SliderItem;
