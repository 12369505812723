import React from 'react';
import { AppData } from '../../../Core/AppData';
import ToggleNavBarItem from './ToggleNavBarItem';
import LanguageButton from '../NavBar/LanguageButton/LanguageButton';

const ToggleNavBar: React.FC<{ closeToggleBar: any }> = ({ closeToggleBar }) => {
    return (
       <nav className="toggle-navbar-wrapper">
           {AppData.map((item, index) => {
               if (item.sectionTitle) {
                   return (
                       <ToggleNavBarItem
                           closeToggleBar={closeToggleBar}
                           key={index}
                           section={item}/>
                   )
               }
           })}
           <div className="toggle-flag-wrapper">
               <LanguageButton/>
           </div>
       </nav>
    );
}

export default ToggleNavBar;
