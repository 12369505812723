import React from 'react';
import CompanyInfo from './CompanyInfo/CompanyInfo';

const Footer = () => {
    return (
        <div
            className="page" id="contact">
            <div
                className="page-content">
                <CompanyInfo/>
            </div>
        </div>
    );
}

export default Footer;
