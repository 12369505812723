import React from 'react';
import { TitleDetailGroupInterface } from '../../Core/Interfaces/TitleDetailGroupInterface';
import { Trans } from 'react-i18next';

const TitleSecondaryCreator: React.FC<{ data: TitleDetailGroupInterface, sectionKey: string }> = ({
                                                                                                      data,
                                                                                                      sectionKey
                                                                                                  }) => {
    return (
        <>
            <div
                className="page-content-part">
                <div
                    className="page-content-sub-title">
                    <Trans
                        i18nKey={sectionKey + "." + data.title}></Trans>
                </div>
                <div
                    className="page-content-sub-detail">
                    <Trans
                        i18nKey={sectionKey + "." + data.detail}></Trans>
                </div>
            </div>
        </>
    );
}

export default TitleSecondaryCreator;
