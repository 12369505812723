import React from 'react';
import { AppData } from '../../Core/AppData';
import { SegmentInterface } from '../../Core/Interfaces/SegmentInterface';
import { SegmentTypeEnum } from '../../Core/Enums/SegmentTypeEnum';
import TitlePrimaryCreator from '../HelperComponents/TitlePrimaryCreator';
import TitleSecondaryCreator from '../HelperComponents/TitleSecondaryCreator';
import LogoGroupCreator from '../HelperComponents/LogoGroupCreator';
import ListCreator from '../HelperComponents/ListCreator';
import TableCreator from '../HelperComponents/TableCreator';
import CardCreator from '../HelperComponents/CardCreator';

const Body = () => {
    const renderSegment = (segment: SegmentInterface, index: number, sectionKey: string) => {
        switch (segment.segmentType) {
            case SegmentTypeEnum.TitleDetailGroupPrimary:
                return <TitlePrimaryCreator
                    key={index}
                    data={segment.titleDetailGroupPrimary!}
                    sectionKey={sectionKey}
                />;
            case SegmentTypeEnum.TitleDetailGroupSecondary:
                return <TitleSecondaryCreator
                    key={index}
                    data={segment.titleDetailGroupSecondary!}
                    sectionKey={sectionKey}
                />;
            case SegmentTypeEnum.LogoGroup:
                return <LogoGroupCreator
                    key={index}
                    data={segment.logoGroup!}
                />;
            case SegmentTypeEnum.List:
                return <ListCreator
                    key={index}
                    data={segment.list!}
                    sectionKey={sectionKey}
                />;
            case SegmentTypeEnum.Table:
                return <TableCreator
                    key={index}/>;
            case SegmentTypeEnum.Card:
                return <CardCreator
                    key={index}
                    data={segment.card!}
                    sectionKey={sectionKey}
                />;
            default:
                return <></>;
        }
    }

    return (
        <>
            {AppData.map((item, index) => {
                if (item.segmentList.length > 0) {
                    return (
                        <div
                            className="page"
                            id={item.sectionKey}
                            key={index}>
                            <div
                                className="page-content">
                                {item.segmentList?.map((segment, i) => (
                                    renderSegment(segment, i, item.sectionKey)
                                ))}
                            </div>
                        </div>
                    )
                }
            })
            }
        </>
    );
}

export default Body;
