import React from 'react';
import './App.scss';
import Header from './Components/Header/Header';
import Body from './Components/Body/Body';
import Footer from './Components/Footer/Footer';

function App() {
    return (
        <>
            <Header/>
            <Body/>
            <Footer/>
        </>
    );
}

export default App;
