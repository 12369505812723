import React from 'react';

const TableCreator = () => {
    return (
        <div
            className="page-content-details">
            <table
                width="100%">
                <thead>
                <tr>
                    <th>
                        <strong>PAKET</strong>
                    </th>
                    <th>
                        <strong>FİYAT</strong>
                    </th>
                    <th>
                        <strong>BİRİM
                            FİYAT</strong>
                    </th>
                    <th>
                        <strong>HEDİYE</strong>
                    </th>
                    <th>
                        <strong>TOPLAM
                            GÖNDERİM
                            HAKKI</strong>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td>2.000.000</td>
                    <td>32.000
                        TL
                        +
                        KDV
                    </td>
                    <td></td>
                    <td>+225.000</td>
                    <td>2.275.000
                        Adet
                    </td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td>1.600.000</td>
                    <td>28.800
                        TL
                        +
                        KDV
                    </td>
                    <td></td>
                    <td>+150.000</td>
                    <td>1.750.000
                        Adet
                    </td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td>1.200.000</td>
                    <td>24.000
                        TL
                        +
                        KDV
                    </td>
                    <td></td>
                    <td>+75.000</td>
                    <td>1.275.000
                        Adet
                    </td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td>800.000</td>
                    <td>17.600
                        TL
                        +
                        KDV
                    </td>
                    <td></td>
                    <td></td>
                    <td>800.000
                        Adet
                    </td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td>400.000</td>
                    <td>9.600
                        TL
                        +
                        KDV
                    </td>
                    <td></td>
                    <td></td>
                    <td>400.000
                        Adet
                    </td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td>200.000</td>
                    <td>5.200
                        TL
                        +
                        KDV
                    </td>
                    <td></td>
                    <td></td>
                    <td>200.000
                        Adet
                    </td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td>100.000</td>
                    <td>2.800
                        TL
                        +
                        KDV
                    </td>
                    <td></td>
                    <td></td>
                    <td>100.000
                        Adet
                    </td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td>50.000</td>
                    <td>1.500
                        TL
                        +
                        KDV
                    </td>
                    <td></td>
                    <td></td>
                    <td>50.000
                        Adet
                    </td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default TableCreator;
