export const TurkishKeys = {
    translations: {
        server: {
            title: "Sunucu",
            slide_title_primary: "Sunucu Kiralama",
            slide_title_secondary: "Web Hosting",
            slide_detail: "İhtiyacınıza yönelik esnek planlarımız ile hizmetinizdeyiz",
            first_segment_title: "Sunucu Kiralama Hizmeti",
            first_segment_detail: "Bir ya da birden fazla sürekli online sunucuya mı ihtiyacınız var? İşletim sistemi ve kapasite sınırı olmaksızın yüksek kalite ve uygun maliyette sanal ya da fiziksel sunucu kiralama hizmeti sunmaktayız.",
            second_segment_title: "Hangi işletim sistemi sizin için uygun?",
            second_segment_detail: "İhtiyaca ve lisanslama koşullarına bağlı olarak seçtiğiniz işletim sisteminizi 20$'dan başlayan fiyatlar ile sunuyoruz.",
            list_title: "Diğer Ücretleri",
            list_item_1: "Ek 1 adet IP 2.5$ + KDV / Ay ( Sanal )",
            list_item_2: "Ek 5 adet IP 2.5$ + KDV / Ay ( Sanal, Fiziksel )",
            list_item_3: "Plesk panel 12$ + KDV / Ay ( Sanal )",
            list_item_4: "Plesk panel 60$ + KDV / Ay ( Fiziksel )"
        },
        hosting: {
            title: "Hosting",
            first_segment_title: "Hosting Hizmeti",
            first_segment_detail: "Web sitenizi yayınlamanın en hesaplı ve profesonel yöntemi ile sürekli yanınızdayız. İhtiyacınıza yönelik esnek seçenekler ile düşük ya da yüksek kapateli her ihtiyacınıza tam cevap verebilecek durumdayız.",
            card_1_title: "Paket Amatör",
            card_1_item_1: "1 Adet e-posta",
            card_1_item_2: "10MB Web Alanı",
            card_1_item_3: "10MB Web Alanı",
            card_1_item_4: "PHP + ASP MYSQL / MsSQL",
            card_1_item_5: "4GB Trafik",
            card_1_item_6: "24TL / Yıl ( KDV Dahil )",
            card_2_title: "Paket Bilmiş",
            card_2_item_1: "3 Adet e-posta adresi",
            card_2_item_2: "250MB Web Alanı",
            card_2_item_3: "250MB E-posta Alanı",
            card_2_item_4: "PHP / ASP",
            card_2_item_5: "MySQL / MsSQL",
            card_2_item_6: "4GB Trafik",
            card_2_item_7: "72TL / Yıl ( KDV Dahil )",
            card_3_title: "Paket Profesyonel",
            card_3_item_1: "10 Adet e-posta adresi",
            card_3_item_2: "10 GB Kota",
            card_3_item_3: "PHP / ASP",
            card_3_item_4: "MySQL / MsSQL",
            card_3_item_5: "4GB Trafik",
            card_3_item_6: "130TL / Yıl ( KDV Dahil )",
            list_title: "Diğer Hosting Ücretleri",
            list_item_1: "1GB kota aşımı 0.2cent + KDV / Ay",
            list_item_2: "1 adet standart e-posta hesabı 12TL + KDV / Yıl",
            list_item_3: "10 adet standart e-posta hesabı 80TL + KDV / Yıl",
            list_item_4: "20 adet standart e-posta hesabı 130TL + KDV / Yıl"
        },
        email: {
            title: "Email",
            slide_title_primary: "Kurumsal Mail",
            slide_title_secondary: "Toplu Mail",
            slide_detail: "Yüksek kalite ve sürekli hizmet sunuyoruz",
            first_segment_title: "Kurumsal Email Hizmeti",
            first_segment_detail: "Günümüz haberleşme yöntemlerinden en önemlisi olan e-posta ile haberleşme tüm firmalar için hassasiyet taşıyan bir konudur. 365 gün kesintisiz hizmet, 7/24 kesintisiz destek ile bu yükü sırtınızdan alıyoruz. Mevcut paketler dışında ihtiyacınıza yönelik olarakta sunulabilen sistemimiz profesyonel ya da basit kullanıma imkan sunabilmektedir.",
            second_segment_title: "",
            second_segment_detail: "Kurumsal Mail hizmetimiz içeriği iki grup altında toplanmaktadır. İhtiyacınıza yönelik seçebileceğiniz ek hizmetler ve standart hizmetlerimiz ile, grup içerikleri maddeler halinde aşağıda iletildiği gibidir.",
            list_1_title: "Standart Hizmetimiz",
            list_1_item_1: "PC / Mobil arayüz",
            list_1_item_2: "15GB ücretsiz kota",
            list_1_item_3: "Dinamik SPAM filitresi ( Kara liste )",
            list_1_item_4: "İçerik filitreleme",
            list_1_item_5: "Antivirüs taraması",
            list_1_item_6: "Tam yedekli akış",
            list_1_item_7: "DKIM, SPF, DMARC",
            list_1_item_8: "Google Drive, Dropbox, OneDrive bağlantı imkanı",
            list_1_item_9: "Firma içi ONLINE yazışma imkanı",
            list_2_title: "Ek Hizmetimiz",
            list_2_item_1: "Cyren Premium Antispam",
            list_2_item_2: "Cyren Zero-hour Antivirus",
            list_2_item_3: "Exchange ActiveSync ( EAS )",
            list_2_item_4: "Exchange Web Services ( EWS )"
        },
        bulk_email: {
            first_segment_title: "Toplu Mail Gönderim Hizmeti",
            first_segment_detail: "Müşterileriniz ile iletişiminizi canlı tutmanın en kolay ve maliyetsiz yöntemi düzenli e-posta gönderme yükünü sırtınızdan alıyoruz. Satışlarınızı arttırmak, müşteri dönüşlerinizi hızlandırmak ve müşteri datanızı canlı tutmak için sürekli olarak toplu mail kullanmanızı tavsiye ederiz. Tüm yasal gereklere uygun e-posta içeriği ile Ücretsiz email formatı oluşturma, oluşturulan içeriğin müşteri kategorilerine göre gönderimi ve her ay 10.000 ücretsiz gönderim ile aylık kapasitemiz 7.500.000 adet e-postadır. Önemli uyarı : Firmamız e-posta listesi sağlamamaktadır.",
            second_segment_title: "Toplu Mail Paket Fiyatlarımız",
            second_segment_detail: "10.000 adet üzeri gönderimler için fiyatlarımız aşağıdaki tabloda iletildiği gibidir.",
            list_1_title: "Toplu Mail Hizmet Özellikleri",
            list_1_item_1: "Ücretsiz aylık 10.000 adet gönderim hakkı",
            list_1_item_2: "Hatalı ve geçersiz e-posta adreslerin temizlenmesi",
            list_1_item_3: "Kategori bazlı gönderim",
            list_1_item_4: "Şehir bazlı gönderim",
            list_1_item_5: "Gönderimlerin kartlar üzerinde takibi",
            list_1_item_6: "15GB ücretsiz alan kullanımı"
        },
        services: {
            title: "Hizmetlerimiz",
            slide_title_primary: "Müşteri Yönetimi",
            slide_title_secondary: "ERP & CRM",
            slide_detail: "Tüm datanızı kontrol altına alıyoruz",
            first_segment_title: "ERP & CRM Hakkında",
            first_segment_detail: "Firmamız bünyesinde geliştirlen web tabanlı yazılımların entegre edilmesi ile oluşturulan ERP ve CRM yazılımlarımız ile, küçük / orta / büyük işletmelerin iş yükünü sırtından alarak; data işleme konusunda 7 gün 24 saat hizmet destek portalimiz ve ekibimiz ile, işletmenizi sağlam temellerde ilerletmenize yardımcı oluyoruz. Yazılım, Turizim, Sağlık, Spor, Makina sektörlerinde kullanılan programımızı her geçen gün daha da ileri seviyelere taşımaktayız. "

        },
        support: {
            title: "Destek"
        },
        contact: {
            title: "İletişim",
            slide_title_primary: "Mail listemize katılmak",
            slide_title_secondary: " ister misiniz?",
            slide_detail: ""
        },
        footer: {
            other_numbers: "Diğer Numaralar"
        },
        login: {
            title: "Giriş",
        }
    }
}
