export const scroll = (id: string): void => {
    let element = document.getElementById(id);
    if (element) {
        setTimeout(function () {
            element!.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }, 100);
    }
}
