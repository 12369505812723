import React from 'react';

import NavBar from './NavBar/NavBar';
import Slider from './Slider/Slider';
import ScrollToTopButton from '../HelperComponents/ScrollToTopButton';

const Header = () => {
    return (
        <div
            className="page-start" id="pageHeader">
            <NavBar/>
            <Slider/>
            <ScrollToTopButton/>
        </div>
    );
}

export default Header;
