import React, { createRef, useState } from 'react';
import NavBarItem from './NabBarItem/NavBarItem';
import { AppData } from '../../../Core/AppData';
import LanguageButton from './LanguageButton/LanguageButton';
import ToggleNavBar from '../ToggleNavBar/ToggleNavBar';

const NavBar = () => {
    const [toggleMenuStatus, setToggleMenuStatus] = useState(false);
    const [menuIconSrc, setMenuIconSrc] = useState('-white');
    const menuTopRef:React.RefObject<HTMLDivElement> = createRef();

    const toggleMenuOpen = () => {
        setToggleMenuStatus(true);
        document.getElementsByClassName('toggle-navbar-wrapper')![0].classList.add('toggle-navbar-open');
    }

    const toggleMenuClose = () => {
        setToggleMenuStatus(false);
        document.getElementsByClassName('toggle-navbar-wrapper')![0].classList.remove('toggle-navbar-open');
    }

    document.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop >= 40) {
            menuTopRef.current?.classList.add("menu-top-fixed");
            setMenuIconSrc('');
            return;
        }
        menuTopRef.current?.classList.remove("menu-top-fixed");
        setMenuIconSrc('-white');
    }, true);

    return (
        <div
            className="menu-top"
            ref={menuTopRef}>
            <div
                className="company-logo">
                <h1>KIRILMAZ</h1>
            </div>
            <div
                className="kirilmaz-services">
                <div>
                    <a href="/code/public#Start"
                       className="link-services">Ana
                        Sayfa</a><br/>
                    <a href="src/Components/Header/Header"
                       className="link-services"
                       target="_blank">Kırılmaz</a><br/>
                    <span>Gizlilik Politikası</span><br/>
                </div>
            </div>
            <div
                className="menu-top-container">
                <div
                    className="margin-left-auto"></div>
                {AppData.map((item, index) => {
                    if (item.sectionTitle) {
                        return (
                            <NavBarItem
                                key={index}
                                section={item}/>
                        )
                    }
                })}
                <LanguageButton/>
                {toggleMenuStatus ?
                    <img
                        id="menuIcon"
                        onClick={toggleMenuClose}
                        className="menu-icon"
                        src={"/img/icon.close" + menuIconSrc + ".png"}
                        alt="logo"/> :
                    <img
                        id="menuIcon"
                        onClick={toggleMenuOpen}
                        className="menu-icon"
                        src={"/img/icon.menu" + menuIconSrc + ".png"}
                        alt="logo"/>}

            </div>
            <ToggleNavBar
                closeToggleBar={toggleMenuClose}/>
        </div>
    );
}

export default NavBar;

// <img
//     src="/img/logo.kirilmaz.png"
//     className="logo-kirilmaz"
//     // alt="logo"/>
