import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageButton = () => {
    const {
        t,
        i18n
    } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng).then(() => {
            window.location.reload();
        });

    };
    return (
        <>
            <button
                className="flag"
                onClick={() => changeLanguage("en")}>
                <img
                    src="/img/gb.png"
                    alt=""/>
            </button>
            <button
                className="flag"
                onClick={() => changeLanguage("en")}>
                <img
                    src="/img/ro.png"
                    alt=""/>
            </button>
            <button
                className="flag"
                onClick={() => changeLanguage("tr")}>
                <img
                    src="/img/tr.png"
                    alt=""/>
            </button>

        </>
    );
}

export default LanguageButton;
