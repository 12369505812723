import { CmsDataInterface } from './Interfaces/CmsDataInterface';
import { SegmentTypeEnum } from './Enums/SegmentTypeEnum';

export const AppData: CmsDataInterface[] = [
    {
        sectionTitle: "Sunucu",
        sectionKey: "server",
        sectionUrl: "server",
        hasSlider: true,
        sliderData: {
            imageUrl: "img/bg.header.aa.jpg"
        },
        segmentList: [
            {
                segmentType: SegmentTypeEnum.TitleDetailGroupPrimary,
                titleDetailGroupPrimary: {
                    title: "first_segment_title",
                    detail: "first_segment_detail"
                },
            },
            {
                segmentType: SegmentTypeEnum.LogoGroup,
                logoGroup: [
                    {
                        url: "img/dedicated.png",
                        alt: "img-dedicated"
                    },
                    {
                        url: "img/cloud.png",
                        alt: "img-cloud"
                    }
                ]
            },
            {
                segmentType: SegmentTypeEnum.TitleDetailGroupSecondary,
                titleDetailGroupSecondary: {
                    title: "second_segment_title",
                    detail: "second_segment_detail"
                }
            },
            {
                segmentType: SegmentTypeEnum.LogoGroup,
                logoGroup: [
                    {
                        url: "img/os.brands.png",
                        alt: "img-brands",
                        width: "100%",
                        className: "full-width"
                    }
                ]
            },
            {
                segmentType: SegmentTypeEnum.List,
                list: {
                    title: "list_title",
                    items: [
                        "list_item_1",
                        "list_item_2",
                        "list_item_3",
                        "list_item_4",
                    ]
                }
            }
        ]
    },
    {
        sectionTitle: "Hosting",
        sectionKey: "hosting",
        sectionUrl: "hosting",
        hasSlider: false,
        segmentList: [
            {
                segmentType: SegmentTypeEnum.TitleDetailGroupPrimary,
                titleDetailGroupPrimary: {
                    title: "first_segment_title",
                    detail: "first_segment_detail"
                },
            },
            {
                segmentType: SegmentTypeEnum.LogoGroup,
                logoGroup: [
                    {
                        url: "img/hosting.png",
                        alt: "img-hosting",
                    }
                ]
            },
            {
                segmentType: SegmentTypeEnum.Card,
                card: [
                    {
                        title: "card_1_title",
                        items: [
                            "card_1_item_1",
                            "card_1_item_2",
                            "card_1_item_3",
                            "card_1_item_4",
                            "card_1_item_5",
                            "card_1_item_6",
                        ]
                    },
                    {
                        title: "card_2_title",
                        items: [
                            "card_2_item_1",
                            "card_2_item_2",
                            "card_2_item_3",
                            "card_2_item_4",
                            "card_2_item_5",
                            "card_2_item_6",
                            "card_2_item_7",
                        ]
                    },
                    {
                        title: "card_3_title",
                        items: [
                            "card_3_item_1",
                            "card_3_item_2",
                            "card_3_item_3",
                            "card_3_item_4",
                            "card_3_item_5",
                            "card_3_item_6",
                        ]
                    }
                ]
            },
            {
                segmentType: SegmentTypeEnum.List,
                list: {
                    title: "list_title",
                    items: [
                        "list_item_1",
                        "list_item_2",
                        "list_item_3",
                        "list_item_4"
                    ]
                }
            }
        ]
    },
    {
        sectionTitle: "Email",
        sectionKey: "email",
        sectionUrl: "email",
        hasSlider: true,
        sliderData: {
            imageUrl: "img/bg.header.ac.jpg"
        },
        segmentList: [
            {
                segmentType: SegmentTypeEnum.TitleDetailGroupPrimary,
                titleDetailGroupPrimary: {
                    title: "first_segment_title",
                    detail: "first_segment_detail"
                },
            },
            {
                segmentType: SegmentTypeEnum.LogoGroup,
                logoGroup: [
                    {
                        url: "img/compmail.png",
                        alt: "img-compmail"
                    }
                ]
            },
            {
                segmentType: SegmentTypeEnum.TitleDetailGroupSecondary,
                titleDetailGroupSecondary: {
                    title: "second_segment_title",
                    detail: "second_segment_detail"
                }
            },
            {
                segmentType: SegmentTypeEnum.List,
                list: {
                    title: "list_1_title",
                    items: [
                        "list_1_item_1",
                        "list_1_item_2",
                        "list_1_item_3",
                        "list_1_item_4",
                        "list_1_item_5",
                        "list_1_item_6",
                        "list_1_item_7",
                        "list_1_item_8",
                        "list_1_item_9"
                    ]
                }
            },
            {
                segmentType: SegmentTypeEnum.List,
                list: {
                    title: "list_2_title",
                    items: [
                        "list_2_item_1",
                        "list_2_item_2",
                        "list_2_item_3",
                        "list_2_item_4"
                    ]
                }
            },
        ]
    },
    {
        sectionKey: "bulk_email",
        hasSlider: false,
        segmentList: [
            {
                segmentType: SegmentTypeEnum.TitleDetailGroupPrimary,
                titleDetailGroupPrimary: {
                    title: "first_segment_title",
                    detail: "first_segment_detail"
                }
            },
            {
                segmentType: SegmentTypeEnum.LogoGroup,
                logoGroup: [
                    {
                        url: "img/massmail.png",
                        alt: "img-massmail"
                    }
                ]
            },
            {
                segmentType: SegmentTypeEnum.List,
                list: {
                    title: "list_1_title",
                    items: [
                        "list_1_item_1",
                        "list_1_item_2",
                        "list_1_item_3",
                        "list_1_item_4",
                        "list_1_item_5",
                        "list_1_item_6"
                    ]
                }
            },
            {
                segmentType: SegmentTypeEnum.TitleDetailGroupSecondary,
                titleDetailGroupSecondary: {
                    title: "second_segment_title",
                    detail: "second_segment_detail"
                }
            },
            {
                segmentType: SegmentTypeEnum.Table
            }
        ]
    },
    {
        sectionTitle: "Hizmetlerimiz",
        sectionKey: "services",
        sectionUrl: "services",
        hasSlider: true,
        sliderData: {
            imageUrl: "img/bg.header.ab.jpg"
        },
        segmentList: [
            {
                segmentType: SegmentTypeEnum.TitleDetailGroupPrimary,
                titleDetailGroupPrimary: {
                    title: "first_segment_title",
                    detail: "first_segment_detail"
                }
            },
            {
                segmentType: SegmentTypeEnum.LogoGroup,
                logoGroup: [
                    {
                        url: "img/crm.png",
                        alt: "img-crm"
                    }
                ]
            }
        ]
    },
    {
        sectionTitle: "Destek",
        sectionKey: "support",
        sectionUrl: "support",
        hasSlider: false,
        segmentList: []
    },
    {
        sectionTitle: "İletişim",
        sectionKey: "contact",
        sectionUrl: "contact",
        hasSlider: true,
        sliderData: {
            imageUrl: "img/bg.header.ae.jpg"
        },
        segmentList: []
    },
    {
        sectionTitle: "Giriş",
        sectionKey: "login",
        sectionUrl: "login",
        hasSlider: false,
        segmentList: []
    }
]


