import React, { useState } from 'react';

const ScrollToTopButton = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        } else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <button
           style={{display: visible ? 'inline' : 'none'}}
           className="menu-top-go-start"
           onClick={scrollToTop}
        >
            <img
                className="menu-top-go-start-image"
                src="/img/icon.up.64x64.white.png"
                alt="logo"/>
        </button>
    );
}

export default ScrollToTopButton;
