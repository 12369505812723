import React from 'react';
import { Trans } from 'react-i18next';
import { CmsDataInterface } from '../../../../Core/Interfaces/CmsDataInterface';
import { scroll } from '../../../../Core/Helpers/ScrollToView'

const NavBarItem: React.FC<{ section: CmsDataInterface }> = ({ section }) => {
    return (
        <div
            className="menu-top-item-right">
            <a href="#"
               onClick={() => {
                   scroll(section.sectionKey);
               }}
               className="menu-top-links">
                <Trans
                    i18nKey={section.sectionKey + ".title"}></Trans>
            </a>
        </div>
    );
}

export default NavBarItem;
